import React, {useContext} from "react";

import PictureModal from "./PictureModal";

import { ValueContext } from "../Context";

function Board({ members, title }) {
  const {setMember} = useContext(ValueContext)
  return (
    <div className="af">
      <div className="board_title">
        <h1>{title}</h1>
      </div>
      <div style={{ display: "none" }}>
        <PictureModal />
      </div>
      <div className="afrivacx_board">
        {members.map((member, i) => (
          <div
            className="member"
            key={i}
            onClick={() => {
              setMember(member)
              document.getElementById("profile_btn").click()
            }}
          >
            <img src={member.profileImage} alt="member" />
            <p>
              <b>{member.details.title}.</b> {member.fullName}
            </p>
            <h4>{member.details.position}</h4>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Board;
