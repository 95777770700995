import React from "react";

import QuickLinks from "../components/QuickLinks";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";

import EventImg from "../data/login.jpg";

function Event() {
  const { event } = useParams();
  return (
    <>
      {/* <Header title="VACCINES" a="/vaccines" /> */}
      <div className="vaccine_body">
        <div className="link">
          <a
            href="/"
            style={{
              color: "black",
              fontSize: "small",
              marginRight: "0.5rem",
            }}
          >
            HOME
          </a>
          <span
            style={{
              color: "black",
              fontSize: "small",
              marginRight: "0.5rem",
            }}
          >
            >
          </span>
          <a
            href="/events"
            style={{
              fontSize: "small",
              color: "black",
              marginRight: "0.5rem",
            }}
          >
            EVENTS
          </a>
          <span
            style={{
              color: "black",
              fontSize: "small",
              marginRight: "0.5rem",
            }}
          >
            >
          </span>
          <a
            href="/events"
            style={{
              fontSize: "small",
              color: "#0d47a1",
              textTransform: "uppercase",
            }}
          >
            {event}
          </a>
        </div>

        <div className="even_body">
          <img
            src="https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714125979/20240422_211247_wioocq.png"
            alt="EventImg"
            style={{ width: "50%" }}
          />
        </div>
        <div className="even_detail">
          <h2>WORLD IMMUNIZATION WEEK WITH AFRIVACX</h2>
          <p style={{ fontWeight: "bold", color: "black" }}>April 24th-30th</p>
          <p>
            Join us this week as we celebrate World Immunization Week with an
            exciting lineup of events
          </p>
          <p style={{ alignItems: "justify" }}>Here's what's coming up:</p>
          <ul>
            <li>
              This Wednesday, join us on Instagram Live with Pharm Laureta
              (@dlaureate) to discuss protecting our little ones through
              immunization.
            </li>
            <li>
              Social Media Challenges: AfriVacx & Africa Public Health Student
              Network Initiative - AfricaPHSN hosting challenges with prizes to
              promote vaccine awareness.
            </li>
            <li>
              Outreach Events: AfriVacx team providing education and awareness
              on vaccinations across various geopolitical zones.
            </li>
            <li>
              LinkedIn Audio Event: Pharm Okechi Nzedibe sharing her expertise
              on the importance of immunization.
            </li>
          </ul>
          <p>
            This Immunization Week, AfriVacx is calling on everyone to get
            involved and join our challenge to raise awareness about the
            importance of vaccines.
          </p>
        </div>
      </div>

      <QuickLinks />
      <Footer />
    </>
  );
}

export default Event;
