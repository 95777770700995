import React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import EventImage from "../data/login.jpg";

import Button1 from "./Button1";
import Button2 from "./Button2";

function HomeEvent() {
  return (
    <div className="afrivacx_events">
      <h1>Our Events</h1>
      <div className="events">
        <div className="current_event">
          <h5>Current event</h5>
          <div className="current_image">
            <Zoom>
              <img
                src="https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714125979/20240422_211247_wioocq.png"
                alt="EventImage"
              />
            </Zoom>
          </div>
          <div className="current_body">
            <p>April 24-30</p>
            <h3>Immunization Week</h3>
            <p style={{ textAlign: "justify" }}>
              Join us as we celebrate World Immunization Week with an exciting
              lineup of events.
            </p>
            <Button1 link="/events/world immunization week" />
          </div>
        </div>
        <div className="next_events">
          <h5>Other Events</h5>
          <div className="next_event">
            <div className="next_event_img">
              <Zoom>
                <img
                  src="https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714131149/Snapinsta.app_440347213_456336070290381_1298089890599788137_n_1080_o0ln4j.jpg"
                  alt=""
                />
              </Zoom>
            </div>
            <p>April 24th-30th</p>
            <h4>Vaccine Awareness Challenge</h4>
          </div>
          <div className="next_event">
            <div className="next_event_image">
              <Zoom>
                <img
                  src="https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714126007/20240424_214107_b0nvkf.png"
                  alt=""
                />
              </Zoom>
            </div>
            <p>April 30th</p>
            <h4>
              How vaccination campaigns can bolster public health resilience:
              Strategies, Challenges and Impact
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeEvent;
