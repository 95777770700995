import * as React from "react";
import { useContext } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { ValueContext } from "../Context";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function PictureModal() {
  const [open, setOpen] = React.useState(false);
  const { member } = useContext(ValueContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <button
        id="profile_btn"
        className="new_chatroom_button"
        onClick={handleClickOpen}
      >
        Profile
      </button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {member.profileImage && (
            <div className="person">
              <div className="person_image">
                <img src={member.profileImage} alt="" />
              </div>
              <div className="person_details">
                <p>
                  <b>{member.details.title}.</b> {member.fullName}
                </p>
                <h5>{member.details.position}</h5>
                <p>{member.details.email}</p>
                <p>{member.details.phoneNumber}</p>
                <p>{member.details.zone}</p>
                <p>{member.details.state}</p>
              </div>
            </div>
          )}
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
