import React from "react";

const images = [
  "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714141268/20240426_150836_sjjubz.png",
  "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714141267/20240426_151005_hujhw0.png",
  "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714141277/20240426_151124_xhicig.png",
  "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714139165/20240426_143250_q6cltx.png",
  "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714139164/20240426_143556_eyjrw8.png",
];

function Collaborations() {
  return (
    <div className="collabs_container">
      <h1>Our Collaborations</h1>
      <div className="collabs">
        {images.map((img, i) => (
          <div className="collab" key={i}>
            <img src={img} alt="member" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Collaborations;
