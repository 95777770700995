import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const events = [
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714134954/20240206_204716_cdtjas.png",
    xs: 6,
    md: 4,
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714134934/20240210_153402_zejazl.png",
    xs: 6,
    md: 4,
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714126880/20240312_232127_cobbxx.png",
    xs: 6,
    md: 4,
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714125990/20240417_111513_mrlb8q.png",
    xs: 6,
    md: 6,
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714126004/20240421_215913_lgqad0.png",
    xs: 6,
    md: 6,
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714126919/20240312_111315_hpb6ul.png",
    xs: 6,
    md: 4,
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714126900/20240310_151427_gdmugu.png",
    xs: 6,
    md: 4,
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714134928/20240126_094036_o3mmxg.png",
    xs: 6,
    md: 4,
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714150708/WhatsApp_Image_2024-04-26_at_15.48.47_gy3y68.jpg",
    xs: 12,
    md: 8,
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714150979/WhatsApp_Image_2024-04-26_at_15.48.48_1_bfbvtp.jpg",
    xs: 6,
    md: 4,
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714152906/WhatsApp_Image_2024-04-26_at_15.48.48_jyszx8.jpg",
    xs: 6,
    md: 4,
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714152591/IMG_4581_xwszw7.jpg",
    xs: 12,
    md: 4,
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714152519/IMG_4_kvekfq.jpg",
    xs: 6,
    md: 4,
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714152516/IMG_3_zkuzrq.jpg",
    xs: 6,
    md: 4,
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714152621/WhatsApp_Image_2024-04-26_at_18.26.48_sxejyg.jpg",
    xs: 12,
    md: 4,
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714152605/WhatsApp_Image_2024-04-26_at_18.23.53_hnx51c.jpg",
    xs: 6,
    md: 4,
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714152609/WhatsApp_Image_2024-04-26_at_18.26.11_negoyv.jpg",
    xs: 6,
    md: 4,
  },
];

export default function FullWidthGrid() {
  return (
    <div className="event_gallery">
      <h3>Past Events</h3>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {events.map((img, i) => (
            <Grid item xs={img.xs} md={img.md}>
              <Item>
                <Zoom>
                  <img src={img.src} alt="" style={{ width: "100%" }} />
                </Zoom>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}
